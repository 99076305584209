<template>
  <div class="d-flex flex-column gap-4">
    <p class="category-title">{{ $t('tasks.defineProductTask.categoryForm.category') }}</p>
    <div :class="displaySelectInColumn ? 'd-flex gap-2 flex-column' : 'd-flex gap-2'">
      <div v-for="({ options, loading }, index) in categories" :key="`cat-${index}`" class="d-flex flex-column gap-1">
        <p>{{ `${$t('tasks.defineProductTask.categoryForm.category')} ${index + 1}` }}</p>
        <el-select
          v-loading="loading"
          :value="categorySelection[index]"
          :class="hasError && options.length !== 0 ? 'error' : ''"
          popper-class="select-category"
          trigger="click"
          :disabled="options.length === 0"
          @change="(selection) => onCategorySelect(index + 1, selection)"
        >
          <el-option
            v-for="(category, catIndex) in options"
            :key="`specific-category-${catIndex}`"
            :label="category.name"
            :value="category.id"
          />
        </el-select>
      </div>
    </div>
  </div>
</template>
<script>
import { computed, ref, toRefs, watch } from 'vue';

import { useCategoriesGraph } from '../../compositions/categoriesGraph';

const findCategoryPathFromTree = (tree, categoryId) => {
  function findCategoryPath(category, path) {
    if (category.id === categoryId) {
      return path;
    }
    if (category.children) {
      for (const child of category.children) {
        const result = findCategoryPath(child, [...path, category]);
        if (result) {
          return result;
        }
      }
    }
    return null;
  }

  for (const category of tree) {
    const path = findCategoryPath(category, []);
    if (path) {
      return path.length > 0 ? path : null;
    }
  }

  return null;
};

const PARENT_CATEGORY_FILTER = 'none';
export default {
  name: 'ProductCategoryForm',
  components: {},
  props: {
    displaySelectInColumn: { type: Boolean, default: false },
    hasError: { type: Boolean, default: false },
    product: { type: Object, default: null },
    categoryTrees: { type: Array, default: () => [] },
  },
  emits: ['on-category-select'],
  setup(props, { emit }) {
    const categorySelection = ref([]);
    const categoryTwoParentId = ref();
    const categoryThreeParentId = ref();
    const finalCategoryId = ref();
    const product = toRefs(props).product;
    const categoryTrees = toRefs(props).categoryTrees;

    const { categoryTrees: categoryOnes, loading: categoryOnesLoading } = useCategoriesGraph(
      ref(PARENT_CATEGORY_FILTER)
    );
    const { categoryTrees: categoryTwos, loading: categoryTwosLoading } = useCategoriesGraph(categoryTwoParentId);
    const { categoryTrees: categoryThrees, loading: categoryThreesLoading } = useCategoriesGraph(categoryThreeParentId);

    const categories = computed(() => [
      { options: categoryOnes.value, loading: categoryOnesLoading.value },
      { options: categoryTwoParentId.value ? categoryTwos.value : [], loading: categoryTwosLoading.value },
      { options: categoryThreeParentId.value ? categoryThrees.value : [], loading: categoryThreesLoading.value },
    ]);

    watch(
      [product, categoryTrees],
      () => {
        if (product.value && categoryTrees.value && categoryTrees.value.length > 0) {
          const path = findCategoryPathFromTree(categoryTrees.value, product.value.categoryId);
          if (path) {
            categoryTwoParentId.value = path[0].id;
            categoryThreeParentId.value = path[1].id;
            finalCategoryId.value = product.value.categoryId;
            categorySelection.value = [
              categoryTwoParentId.value,
              categoryThreeParentId.value,
              product.value.categoryId,
            ];
          }
        }
      },
      { immediate: true, deep: true }
    );

    const onCategorySelect = (categoryNumber, selection) => {
      switch (categoryNumber) {
        case 1:
          categorySelection.value = [selection];
          categoryTwoParentId.value = selection;
          categoryThreeParentId.value = null;
          emit('on-category-select', undefined);
          break;
        case 2:
          categoryThreeParentId.value = selection;
          categorySelection.value = [categorySelection.value[0], selection];
          emit('on-category-select', undefined);
          break;
        case 3:
          finalCategoryId.value = selection;
          categorySelection.value = [categorySelection.value[0], categorySelection.value[1], selection];
          emit('on-category-select', selection);
          break;
      }
    };

    return {
      categories,
      // currentCategory,
      categorySelection,
      onCategorySelect,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.category-title {
  font-size: 1.25rem;
  font-weight: 700;
}
::v-deep {
  .error {
    .el-input__inner {
      border-color: #cc2d4b !important;
    }
  }
}
</style>
<style lang="scss">
.select-category {
  div.el-select-dropdown__wrap {
    overflow: auto;
  }
}
</style>
