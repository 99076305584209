<template>
  <div class="d-flex flex-column gap-4">
    <p class="catalog-title">{{ $t('product.productManagementModal.catalogs') }}</p>
    <div
      v-for="(catalogId, index) in selectedCatalogs"
      :key="`catalog-select-${index}`"
      class="d-flex gap-4 align-items-center"
    >
      <ActionableSelect
        :selected-value="catalogId"
        :style="{ width: '25rem' }"
        :options="catalogOptions"
        :placeholder="$t('select')"
        @on-select="(newCatalogId) => onCatalogSelected(newCatalogId, index)"
        @on-action="() => addNewCatalog(index)"
      >
        <div class="d-flex gap-1 align-items-center">
          <PlusIcon :size="20" />
          <div class="fw-bold">{{ $t('tasks.defineProductTask.catalogForm.createCatalog') }}</div>
        </div>
      </ActionableSelect>
      <div>
        <el-tooltip :content="$t('catalog.products.removeFromCatalog')" placement="top" effect="dark">
          <Button type="icon" @click="() => onRemoveCatalogClick(index)">
            <RemoveIcon fill="#71767D" />
          </Button>
        </el-tooltip>
      </div>
    </div>
    <div
      class="add-another"
      :class="{ enabled: isLastCatalogSelected }"
      @click="() => (isLastCatalogSelected ? addEmptyCatalog() : () => {})"
    >
      <p>+</p>
      <p>{{ $t('tasks.defineProductTask.catalogForm.addCatalog') }}</p>
    </div>
    <AddCatalogModal
      v-if="showCreateCatalogModal"
      :supplier="product?.business"
      :visible="showCreateCatalogModal"
      @on-close="showCreateCatalogModal = false"
      @on-added-catalog="onAddedCatalog"
    />
    <ActionsModal
      v-if="isDeleteModalOpen"
      :toggle-dialog="isDeleteModalOpen"
      :title="$t('catalog.products.removeProductFromCatalogTitle')"
      :content="
        $t('catalog.products.removeProductFromCatalog', {
          productName: product.name,
          catalogName: catalogOptions?.find(({ value }) => selectedCatalogs?.includes(value)).label,
        })
      "
      :confirm-button-text="$t('catalog.products.removeFromCatalog')"
      :cancel-button-text="$t('cancel')"
      :dialog-type="DIALOG_TYPES.WARNING"
      @on-cancel="onCloseDeleteLastCatalogFromProduct"
      @on-close="onCloseDeleteLastCatalogFromProduct"
      @on-confirm="onRemoveLastCatalogFromProduct"
    />
  </div>
</template>
<script>
import { ref, computed, watch } from 'vue';

import { PlusIcon, RemoveIcon } from '@/assets/icons';
import { Button, DIALOG_TYPES } from '@/modules/core';

import AddCatalogModal from '../../../catalog/components/AddCatalogModal.vue';
import ActionableSelect from '../ActionableSelect.vue';
import ActionsModal from '@/modules/core/components/ActionsModal.vue';
import { uniqBy } from 'lodash';

export default {
  name: 'ProductCatalogForm',
  components: { ActionableSelect, AddCatalogModal, PlusIcon, RemoveIcon, Button, ActionsModal },
  props: {
    product: { type: Object, default: null },
    catalogIds: { type: Array, default: () => [] },
    catalogs: { type: Array, default: () => [] },
  },
  emits: ['on-catalog-change'],
  setup(props, { emit }) {
    const selectedCatalogs = ref([]);
    const showCreateCatalogModal = ref(false);
    const productCatalogs = ref([]);
    const createdCatalogs = ref([]);
    const isDeleteModalOpen = ref(false);

    const catalogs = computed(() => props.catalogs);

    watch(
      [catalogs],
      () => {
        const moreSelectedCatalogs = [];
        catalogs.value.forEach(({ productIds, id }) => {
          if (productIds.includes(props.product.id) || props.catalogIds?.includes(id)) {
            moreSelectedCatalogs.push(id);
          }
        });

        productCatalogs.value = [...moreSelectedCatalogs];
        if (moreSelectedCatalogs.length === 0) {
          selectedCatalogs.value = [];
        } else {
          selectedCatalogs.value = [...new Set([...moreSelectedCatalogs, ...selectedCatalogs.value])];
        }
      },
      { immediate: true, deep: true }
    );

    watch([productCatalogs, selectedCatalogs], () => {
      const addedCatalogs = selectedCatalogs.value.filter(
        (catalog) => catalog !== null && !productCatalogs.value.includes(catalog)
      );

      const removedCatalogs = productCatalogs.value.filter((catalog) => !selectedCatalogs.value.includes(catalog));

      emit('on-catalog-change', {
        catalogsToAdd: addedCatalogs,
        catalogsToRemove: removedCatalogs,
        productCatalogs: productCatalogs.value,
      });
    });

    const catalogOptions = computed(() =>
      uniqBy([...createdCatalogs.value, ...catalogs.value], 'id').map(({ id, name }) => ({
        value: id,
        label: name,
        disabled: selectedCatalogs.value.includes(id),
      }))
    );

    const isLastCatalogSelected = computed(() => selectedCatalogs.value[selectedCatalogs.value.length - 1] !== null);

    const addEmptyCatalog = () => {
      selectedCatalogs.value.push(null);
    };

    const onCatalogSelected = (newCatalogId, index) => {
      const clonedSelectedCatalogs = [...selectedCatalogs.value];
      clonedSelectedCatalogs[index] = newCatalogId;

      selectedCatalogs.value = clonedSelectedCatalogs;
    };

    const addNewCatalog = (index) => {
      const clonedSelectedCatalogs = [...selectedCatalogs.value];
      clonedSelectedCatalogs[index] = null;

      selectedCatalogs.value = clonedSelectedCatalogs;
      showCreateCatalogModal.value = true;
    };

    const onRemoveCatalogClick = (index) => {
      if (!index && selectedCatalogs.value.length === 1) {
        isDeleteModalOpen.value = true;
        return;
      }

      removeCatalogFromIndex(index);
    };

    const removeCatalogFromIndex = (index) => {
      const clonedSelectedCatalogs = [...selectedCatalogs.value];
      clonedSelectedCatalogs.splice(index, 1);

      selectedCatalogs.value = clonedSelectedCatalogs;
    };

    const onAddedCatalog = (newCatalog) => {
      showCreateCatalogModal.value = false;
      const indexToUpdate = selectedCatalogs.value.findIndex((id) => id === null);
      const clonedSelectedCatalogs = [...selectedCatalogs.value];
      clonedSelectedCatalogs[indexToUpdate] = newCatalog.id;
      selectedCatalogs.value = clonedSelectedCatalogs;
      createdCatalogs.value.push(newCatalog);
    };

    const onCloseDeleteLastCatalogFromProduct = () => {
      isDeleteModalOpen.value = false;
    };

    const onRemoveLastCatalogFromProduct = () => {
      removeCatalogFromIndex(0);
      onCloseDeleteLastCatalogFromProduct();
    };

    return {
      catalogOptions,
      selectedCatalogs,
      isLastCatalogSelected,
      showCreateCatalogModal,
      addNewCatalog,
      addEmptyCatalog,
      onAddedCatalog,
      onCatalogSelected,
      removeCatalogFromIndex,
      onCloseDeleteLastCatalogFromProduct,
      onRemoveLastCatalogFromProduct,
      isDeleteModalOpen,
      DIALOG_TYPES,
      onRemoveCatalogClick,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.catalog-title {
  color: #131416;
  font-size: 1.25rem;
  font-weight: 700;
}
.add-another {
  display: flex;
  width: 6rem;
  gap: 0.25rem;
  align-items: center;
  color: $typography-secondary;
}
.enabled {
  cursor: pointer;
  color: #0d50d4;
}
</style>
