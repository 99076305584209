import i18n from '@/imports/startup/client/i18n';

export const MAP_PRODUCTS_TASK_HEADERS = {
  SKU: 'sku',
  PRODUCT: 'product',
  REASON: 'reason',
};

export const EXCEEDING_PRICE_PRODUCTS_HEADERS = {
  SKU: 'sku',
  PRODUCT: 'product',
  ORDER_DATE: 'orderDate',
  QUANTITY: 'quantity',
  PRICE_PER_QUANTITY: 'pricePerQuantity',
  TOTAL: 'total',
  COMPARISON: 'comparison',
};

export const BOM_HEADERS = {
  BILLING_UNIT: 'billingUnit',
  FIRST_BOM: 'firstBom',
  SECOND_BOM: 'secondBom',
  THIRD_BOM: 'thirdBom',
};

const getTranslation = (key) => i18n.t(`tasks.mapProductTask.table.headers.${key}`);
const getExceedingPriceTranslation = (key) => i18n.t(`tasks.mapProductTask.exceedingPriceModal.table.headers.${key}`);
const getBomTableTranslation = (key) => i18n.t(`tasks.mapProductTask.exceedingPriceModal.bomTable.headers.${key}`);

export const getProductsMapColumns = () => [
  {
    header: getTranslation(MAP_PRODUCTS_TASK_HEADERS.SKU),
    key: MAP_PRODUCTS_TASK_HEADERS.SKU,
    width: '100px',
  },
  {
    header: getTranslation(MAP_PRODUCTS_TASK_HEADERS.PRODUCT),
    key: MAP_PRODUCTS_TASK_HEADERS.PRODUCT,
    width: '250px',
  },
  {
    header: getTranslation(MAP_PRODUCTS_TASK_HEADERS.REASON),
    key: MAP_PRODUCTS_TASK_HEADERS.REASON,
    width: '100px',
  },
];

export const getExceedingPriceProductsColumns = (isSingleView = false) => [
  {
    header: getExceedingPriceTranslation(EXCEEDING_PRICE_PRODUCTS_HEADERS.SKU),
    key: EXCEEDING_PRICE_PRODUCTS_HEADERS.SKU,
    width: '100px',
  },
  {
    header: getExceedingPriceTranslation(EXCEEDING_PRICE_PRODUCTS_HEADERS.PRODUCT),
    key: EXCEEDING_PRICE_PRODUCTS_HEADERS.PRODUCT,
    width: isSingleView ? '280px' : '220px',
  },
  {
    header: getExceedingPriceTranslation(EXCEEDING_PRICE_PRODUCTS_HEADERS.ORDER_DATE),
    key: EXCEEDING_PRICE_PRODUCTS_HEADERS.ORDER_DATE,
    width: '120px',
  },
  {
    header: getExceedingPriceTranslation(EXCEEDING_PRICE_PRODUCTS_HEADERS.QUANTITY),
    key: EXCEEDING_PRICE_PRODUCTS_HEADERS.QUANTITY,
    width: '80px',
  },
  {
    header: getExceedingPriceTranslation(EXCEEDING_PRICE_PRODUCTS_HEADERS.PRICE_PER_QUANTITY),
    key: EXCEEDING_PRICE_PRODUCTS_HEADERS.PRICE_PER_QUANTITY,
    width: isSingleView ? '120px' : '88px',
  },
  {
    header: getExceedingPriceTranslation(EXCEEDING_PRICE_PRODUCTS_HEADERS.TOTAL),
    key: EXCEEDING_PRICE_PRODUCTS_HEADERS.TOTAL,
    width: '120px',
  },
  isSingleView
    ? { key: EXCEEDING_PRICE_PRODUCTS_HEADERS.COMPARISON }
    : {
        header: '',
        key: EXCEEDING_PRICE_PRODUCTS_HEADERS.COMPARISON,
        width: '72px',
        customClass: 'p-0',
      },
];

export const getBomTableColumns = () => [
  {
    header: getBomTableTranslation(BOM_HEADERS.BILLING_UNIT),
    key: BOM_HEADERS.BILLING_UNIT,
    width: '100px',
  },
  {
    header: getBomTableTranslation(BOM_HEADERS.FIRST_BOM),
    key: BOM_HEADERS.FIRST_BOM,
    width: '100px',
  },
  {
    header: getBomTableTranslation(BOM_HEADERS.SECOND_BOM),
    key: BOM_HEADERS.SECOND_BOM,
    width: '100px',
  },
  {
    header: getBomTableTranslation(BOM_HEADERS.THIRD_BOM),
    key: BOM_HEADERS.THIRD_BOM,
    width: '100px',
  },
];
