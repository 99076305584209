<template>
  <div class="d-flex flex-column gap-4">
    <p class="bom-title">{{ $t('tasks.defineProductTask.uom') }}</p>
    <div class="d-flex flex-column bom-container">
      <div v-for="(bom, index) in allBoms" :key="`bom-${index}`" class="d-flex flex-column gap-2">
        <div class="d-flex gap-4">
          <div class="d-flex flex-column gap-1">
            <p>{{ bom.title }}</p>
            <el-select
              :value="bom.unit"
              popper-class="select-bom"
              :class="bomExistingError ? 'error' : ''"
              trigger="click"
              @change="(selection) => onBomSelect(index, selection)"
            >
              <el-option
                v-for="(unit, uIndex) in ALL_UNITS"
                :key="`unit-${uIndex}`"
                :label="$t(`tasks.defineProductTask.bomForm.units.${unit}`)"
                :value="unit"
              />
            </el-select>
          </div>
          <div class="d-flex flex-column gap-1 quantity">
            <p>{{ $t('tasks.defineProductTask.bomForm.quantity') }}</p>
            <el-input
              v-model="bom.quantity"
              :class="bomExistingError || doesQuantityHaveError(index) ? 'error' : ''"
              type="number"
              @input="triggerBomEmitter"
            />
          </div>
          <div v-if="isBomRemovable(index)" class="remove-bom">
            <Button type="icon" @click="isLatestBOMFilled ? (isActionsModalOpen = true) : onBomRemove()">
              <RemoveIcon fill="#71767D" />
            </Button>
          </div>
        </div>
        <el-radio-group :value="bom.billingUnit" @change="() => onBillingUnitSelect(index)">
          <el-radio :label="true" class="m-0 py-2">
            <p
              :class="
                showBillingUnitError && hasBillingUnitError
                  ? 'd-inline-flex error'
                  : 'd-inline-flex text-typography-primary'
              "
            >
              {{ $t('tasks.defineProductTask.bomForm.billingUnit') }}
            </p>
          </el-radio>
        </el-radio-group>
      </div>
      <div
        v-if="allBoms.length < 3"
        class="add-another"
        :class="{ enabled: isLatestBOMFilled }"
        @click="() => (isLatestBOMFilled ? addBom() : () => {})"
      >
        <p>+</p>
        <p class="another-bom">{{ $t('tasks.defineProductTask.bomForm.addAnother') }}</p>
      </div>
    </div>
    <ActionsModal
      v-if="isActionsModalOpen"
      :toggle-dialog="isActionsModalOpen"
      :title="$t('tasks.defineProductTask.bomForm.removeBom.title')"
      :content="$t('tasks.defineProductTask.bomForm.removeBom.content')"
      :confirm-button-text="$t('tasks.defineProductTask.bomForm.removeBom.delete')"
      :cancel-button-text="$t('cancel')"
      :dialog-type="DIALOG_TYPES.DANGER"
      @on-cancel="isActionsModalOpen = false"
      @on-close="isActionsModalOpen = false"
      @on-confirm="onBomRemove"
    />
  </div>
</template>
<script>
import { ref, computed, watch, getCurrentInstance, toRefs } from 'vue';
import { RemoveIcon } from '@/assets/icons';
import { Button, ActionsModal, DIALOG_TYPES } from '@/modules/core';

const units = {
  mass: ['kg', 'g'],
  volume: ['l', 'ml'],
  generic: ['bottle', 'unit', 'package'],
};

export const ALL_UNITS = [...units.mass, ...units.volume, ...units.generic];
export const BOM_ERRORS = {
  NO_BOM: 'noBom',
  MISSING_QUANTITY: 'missingQuantity',
  NO_BILLING_UNIT: 'noBillingUnit',
  LAST_BOM_MEASURABLE: 'lastBomMeasurable',
};

const bomDefaults = { unit: undefined, quantity: undefined, billingUnit: false };

export default {
  name: 'ProductBOMForm',
  components: { Button, RemoveIcon, ActionsModal },
  props: {
    errors: { type: Array, required: true },
    showBillingUnitError: { type: Boolean, default: false },
    product: { type: Object, default: null },
  },
  emits: ['on-bom-change'],
  setup(props, { emit }) {
    const root = getCurrentInstance().proxy;
    const isActionsModalOpen = ref(false);
    const product = toRefs(props).product;
    const currentProductId = ref(null);

    const bomTitles = [
      `${root.$t('tasks.defineProductTask.bomForm.orderUnit')} (BOM 1)`,
      `${root.$t('tasks.defineProductTask.bomForm.secondaryUnit')} (BOM 2)`,
      `${root.$t('tasks.defineProductTask.bomForm.thirdUnit')} (BOM 3)`,
    ];

    const allBoms = ref([
      {
        ...bomDefaults,
        title: bomTitles[0],
      },
    ]);

    const bomExistingError = computed(() => props.errors.find(({ error }) => error === BOM_ERRORS.NO_BOM));
    const doesQuantityHaveError = (currentQuantityIndex) => {
      const hasMissingQuantity = props.errors.find(
        ({ error, index }) => error === BOM_ERRORS.MISSING_QUANTITY && index === currentQuantityIndex
      );

      return hasMissingQuantity !== undefined;
    };
    const hasBillingUnitError = computed(() => props.errors.find(({ error }) => error === BOM_ERRORS.NO_BILLING_UNIT));

    watch(
      product,
      () => {
        if (product.value) {
          let resetBom = false;
          if (currentProductId.value !== product.value.id) {
            currentProductId.value = product.value.id;
            resetBom = true;
          }
          let currentBom = product.value.bom;
          let index = 0;
          const allExistingBoms = [];
          while (currentBom) {
            allExistingBoms.push({
              ...currentBom,
              unit: currentBom.uom,
              title: bomTitles[index],
            });
            currentBom = currentBom.bom;
            index += 1;
          }
          if (allExistingBoms.length > 0) {
            allBoms.value = allExistingBoms;
          } else if (resetBom) {
            allBoms.value = [
              {
                ...bomDefaults,
                title: bomTitles[0],
              },
            ];
          }
        }
      },
      { immediate: true, deep: true }
    );

    const onBomSelect = (index, selection) => {
      allBoms.value[index].unit = selection;
      triggerBomEmitter();
    };

    const onBomRemove = () => {
      allBoms.value.pop();
      isActionsModalOpen.value = false;
      triggerBomEmitter();
    };

    const addBom = () => {
      if (allBoms.value.length === 1) {
        allBoms.value.push({
          ...bomDefaults,
          title: bomTitles[1],
        });
        triggerBomEmitter();
      } else {
        allBoms.value.push({
          ...bomDefaults,
          title: bomTitles[2],
        });
        triggerBomEmitter();
      }
    };

    const onBillingUnitSelect = (index) => {
      allBoms.value = allBoms.value.map((bom, i) =>
        i === index ? { ...bom, billingUnit: true } : { ...bom, billingUnit: false }
      );
      triggerBomEmitter();
    };

    const triggerBomEmitter = () => {
      emit('on-bom-change', allBoms.value);
    };

    const isBomRemovable = (index) => allBoms.value.length - 1 === index && index > 0;

    const isLatestBOMFilled = computed(() => {
      const lastIndex = allBoms.value.length - 1;
      const lastBOM = allBoms.value[lastIndex];

      const isQuantityEmpty = lastBOM.quantity === undefined || lastBOM.quantity === '';
      const isUnitEmpty = lastBOM.unit === undefined;

      return !isQuantityEmpty && !isUnitEmpty;
    });

    return {
      ALL_UNITS,
      DIALOG_TYPES,
      allBoms,
      bomExistingError,
      isLatestBOMFilled,
      isActionsModalOpen,
      hasBillingUnitError,
      addBom,
      onBomSelect,
      onBomRemove,
      isBomRemovable,
      triggerBomEmitter,
      onBillingUnitSelect,
      doesQuantityHaveError,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/stylesheets/scss/global';
.bom-title {
  font-size: 1.25rem;
  font-weight: 700;
}
.bom-container {
  gap: 2rem;
}
::v-deep {
  div.quantity {
    width: 6.25rem;

    input[type='number'] {
      -moz-appearance: textfield;
    }

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .error {
    .el-input__inner {
      border-color: #cc2d4b !important;
    }
    color: #cc2d4b !important;
  }
}
.add-another {
  display: flex;
  width: 5rem;
  gap: 0.25rem;
  align-items: center;
  color: $typography-secondary;
  .another-bom {
    text-decoration: underline;
  }
}
.enabled {
  cursor: pointer;
  color: #0d50d4;
}
.remove-bom {
  align-self: center;
  margin-top: 24px;
}
</style>
<style lang="scss">
.select-bom {
  div.el-select-dropdown__wrap {
    overflow: auto;
  }
}
</style>
