<template>
  <div class="d-flex flex-column gap-4 border rounded p-4 product-details">
    <p class="product-details-title">{{ $t('tasks.defineProductTask.productDetails.title') }}</p>
    <div class="d-flex flex-column gap-4">
      <div v-for="(detail, index) in details" :key="`product-detail-${index}`" class="d-flex gap-2">
        <p class="product-detail-row-title">{{ detail.title }}</p>
        <p>{{ detail.value }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import { computed, getCurrentInstance } from 'vue';

export default {
  components: {},
  props: {
    product: { type: Object, default: null },
  },
  setup(props) {
    const root = getCurrentInstance().proxy;

    const details = computed(() => [
      { title: root.$t('tasks.defineProductTask.productDetails.name'), value: props.product?.name },
      { title: root.$t('tasks.defineProductTask.productDetails.clarityId'), value: props.product?.id },
      { title: root.$t('tasks.defineProductTask.productDetails.sku'), value: props.product?.sku },
      { title: root.$t('tasks.defineProductTask.productDetails.supplier'), value: props.product?.business?.name },
    ]);

    return {
      details,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.product-details {
  .product-details-title {
    font-size: 1.25rem;
    font-weight: 700;
  }

  .product-detail-row-title {
    width: 13.5rem;
    color: $typography-secondary;
  }
}
</style>
